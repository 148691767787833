import React from 'react'
import PropTypes from 'prop-types'

import Layout from '~components/Layout'
import Typography from '~components/Typography'
import { NAVIGATION } from '~utils/constants'

import messages from './messages'
import Header from './Header'
import Content from './Content'
import Services from './Services'

const metaTags = {
  title: 'Gruppentraining und Firmenfitness',
  description:
    'Mit gezieltem Training die Gesundheit fördern. Für Gruppentraining, Firmenfitness und Workshops. Mehr erfährst du hier!',
  slug: NAVIGATION.SERVICE.GROUP_FITNESS,
  image: {
    url: 'PageServiceGroupFitness/HeroGroupFitness_01--large',
    alt: 'Zwei Kunden die auf einer Wiese trainieren',
  },
}

const hero = {
  pictureData: {
    path: 'PageServiceGroupFitness',
    alt: 'Zwei Kunden die auf einer Wiese trainieren',
    fallback: {
      name: 'HeroGroupFitness_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE1IDI1NzgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1YzdjNTciIGQ9Ik0wIDBoNjAxNXYyNTYxSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS43IDExLjcpIHNjYWxlKDIzLjQ5NjEpIiBmaWxsLW9wYWNpdHk9Ii41Ij48ZWxsaXBzZSBmaWxsPSIjZmZmIiBjeD0iMTg4IiBjeT0iNDIiIHJ4PSIzMSIgcnk9IjIwIi8+PGVsbGlwc2UgZmlsbD0iIzY4ZDBmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg1MC43MTc3IDUwLjY2MDMgLTE5LjI1ODY3IDE5LjI4MDUgNDAuNSAyNC44KSIvPjxlbGxpcHNlIGZpbGw9IiMyYjQyMDAiIGN4PSIyMzQiIGN5PSI4NiIgcng9IjE0MSIgcnk9IjI1Ii8+PGVsbGlwc2UgZmlsbD0iIzI1NGMwMCIgY3g9IjI3IiBjeT0iNzkiIHJ4PSI0NiIgcnk9IjMwIi8+PGVsbGlwc2UgZmlsbD0iIzBiMTMwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMzUuNDIwMzMgMjMuMjQzNTQgLTEzLjI5OTUgLTIwLjI2NjggMjE0LjUgMCkiLz48ZWxsaXBzZSBmaWxsPSIjZmZlMGJhIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDMuMjEwMzYgLjU4ODcgLTkuNzA0MyA1Mi45MjEwOCAxNTcuNSA3NykiLz48ZWxsaXBzZSBmaWxsPSIjMDdiMmZmIiBjeD0iNDUiIGN5PSI0MSIgcng9IjM0IiByeT0iMTUiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjE1LjcgNTIuMUwxODkgNjhsLTIwLTQyIDUzIDEyLjN6Ii8+PC9nPjwvc3ZnPg==',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroGroupFitness_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNDM3IDI1NzgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM2MjgyNWMiIGQ9Ik0wIDBoMzQzN3YyNTc3SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjcgNi43KSBzY2FsZSgxMy40MjU3OCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoOC41NDQ4MiAtMzIuMzQwODYgNTEuNTkyNTIgMTMuNjMxMzMgMjQ0LjQgODEpIi8+PGVsbGlwc2UgZmlsbD0iIzI3YzhmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMjIuMzc2ODIgMTIuNTUyMzMgLTUyLjY4MTMyIC05My45MTQwNCAyNSA2OC4zKSIvPjxlbGxpcHNlIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoNDYuMTIxOSAuNzk0MTMgLS4yOTgwMSAxNy4zMDgwNyAxMjcuNiAxNDcpIi8+PGVsbGlwc2UgZmlsbD0iIzFjNDUwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMTYuMTc0MDIgNDMuMDcyMjQgLTMxLjU0NDQ2IC0xMS44NDUyMyAyMzEuNCAxNTcpIi8+PGVsbGlwc2UgZmlsbD0iI2YzZTJmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMS44MDI0NyAxMC4zMzA4IC00MC41OTc4MiAtNy4wODMzIDY3LjIgNTguMikiLz48ZWxsaXBzZSBmaWxsPSIjMzI1YzAwIiBjeD0iMTciIGN5PSIxNjEiIHJ4PSIzMiIgcnk9IjY2Ii8+PGVsbGlwc2UgZmlsbD0iIzFlMWExMiIgY3g9IjIyNyIgY3k9IjE0IiByeD0iMTA5IiByeT0iMTYiLz48cGF0aCBmaWxsPSIjZmZkNWFiIiBkPSJNMTg0IDE3N2wzNC04Mi0xNC04NXoiLz48L2c+PC9zdmc+',
      },
      breakpointMedium: {
        name: 'HeroGroupFitness_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODY3IDI1NzgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM2MjgzNWYiIGQ9Ik0wIDBoMzg2N3YyNTY3SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjYgNy42KSBzY2FsZSgxNS4xMDU0NykiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoOTcuOCA4NS4zIDEzNykgc2NhbGUoMzAuNTA0OTEgNDYuMjc4MzQpIi8+PGVsbGlwc2UgZmlsbD0iIzNmY2VmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTIxLjYgMzAuMiAxNy42KSBzY2FsZSgxMDUuOTI4OTEgMjUuNTI5NDUpIi8+PGVsbGlwc2UgY3g9IjEzNCIgY3k9IjEzMCIgcng9IjM3IiByeT0iMTYiLz48ZWxsaXBzZSBmaWxsPSIjMjY1MjAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDE1Ny42IDIuNSA2OS44KSBzY2FsZSgzMy44ODg5NiA1MS44MTY3NSkiLz48ZWxsaXBzZSBmaWxsPSIjMWI0NDAwIiBjeD0iMjMxIiBjeT0iMTQ2IiByeD0iNDAiIHJ5PSI0NSIvPjxwYXRoIGQ9Ik0yMjIuMSAzMy42bC44LTQyIDQxIC44LS44IDQyeiIvPjxlbGxpcHNlIGZpbGw9IiM0OGM3ZmYiIGN4PSIyMyIgY3k9IjU3IiByeD0iNjAiIHJ5PSIyNSIvPjxwYXRoIGZpbGw9IiNmZGMyOWYiIGQ9Ik0zMS44LS40bC0xLjYgNDQtMzYtMS4yIDEuNi00NHoiLz48L2c+PC9zdmc+',
      },
      breakpointLarge: {
        name: 'HeroGroupFitness_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE1IDI1NzgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1YzdjNTciIGQ9Ik0wIDBoNjAxNXYyNTYxSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS43IDExLjcpIHNjYWxlKDIzLjQ5NjEpIiBmaWxsLW9wYWNpdHk9Ii41Ij48ZWxsaXBzZSBmaWxsPSIjZmZmIiBjeD0iMTg4IiBjeT0iNDIiIHJ4PSIzMSIgcnk9IjIwIi8+PGVsbGlwc2UgZmlsbD0iIzY4ZDBmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg1MC43MTc3IDUwLjY2MDMgLTE5LjI1ODY3IDE5LjI4MDUgNDAuNSAyNC44KSIvPjxlbGxpcHNlIGZpbGw9IiMyYjQyMDAiIGN4PSIyMzQiIGN5PSI4NiIgcng9IjE0MSIgcnk9IjI1Ii8+PGVsbGlwc2UgZmlsbD0iIzI1NGMwMCIgY3g9IjI3IiBjeT0iNzkiIHJ4PSI0NiIgcnk9IjMwIi8+PGVsbGlwc2UgZmlsbD0iIzBiMTMwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMzUuNDIwMzMgMjMuMjQzNTQgLTEzLjI5OTUgLTIwLjI2NjggMjE0LjUgMCkiLz48ZWxsaXBzZSBmaWxsPSIjZmZlMGJhIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDMuMjEwMzYgLjU4ODcgLTkuNzA0MyA1Mi45MjEwOCAxNTcuNSA3NykiLz48ZWxsaXBzZSBmaWxsPSIjMDdiMmZmIiBjeD0iNDUiIGN5PSI0MSIgcng9IjM0IiByeT0iMTUiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjE1LjcgNTIuMUwxODkgNjhsLTIwLTQyIDUzIDEyLjN6Ii8+PC9nPjwvc3ZnPg==',
      },
    },
  },
  textData: {
    header: messages.header,
  },
}

const PageServiceGroupFitness = ({ location }) => (
  <Layout
    hero={hero}
    pictureData={hero.pictureData}
    metaTags={metaTags}
    pathname={location.pathname}
  >
    <Content>
      <Header as="h2" type="header2" limit align="center" uppercase>
        {messages.title1}
      </Header>
      <Typography limit>{messages.paragraph1}</Typography>
      <Services />
    </Content>
  </Layout>
)

PageServiceGroupFitness.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PageServiceGroupFitness
