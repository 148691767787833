import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { shadowsArray } from '~styles/constants'
import Trapezoid, { Triangle, trianglePosition } from '~components/Trapezoid'

const StyledTrapezoid = styled(Trapezoid)`
  z-index: 1;
  background: ${({ theme, position }) =>
    position === 'right'
      ? theme.palette.primary.main
      : theme.palette.grey[200]};
  filter: drop-shadow(${shadowsArray[0][0]}) drop-shadow(${shadowsArray[0][1]});
  ${media.mq234`
    grid-row: 1 / 2;
    grid-column: ${({ position }) => (position === 'left' ? '2 / 4' : '1 / 3')};
  `};

  & > ${Triangle} {
    fill: ${({ theme, position }) =>
      position === 'right'
        ? theme.palette.primary.main
        : theme.palette.grey[200]};
    ${media.mq1`
      height: 3vh;
      ${({ position }) =>
        position === 'right'
          ? trianglePosition.top.left
          : trianglePosition.top.right}
    `};
    ${media.mq234`
      width: 10%;
      ${({ position }) =>
        position === 'right'
          ? trianglePosition.right.bottom
          : trianglePosition.left.top}
    `};
  }
`

export default StyledTrapezoid
