import styled from 'styled-components'

import Img from '~components/Img'
import { media } from '~styles/mediaQuery'

const ServiceImage = styled(Img)`
  ${media.mq234`
    grid-row: 1 / -1;
    grid-column: ${({ position }) => (position === 'left' ? '1 / 3' : '2 / 4')};
  `};
`

export default ServiceImage
