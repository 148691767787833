import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const ServiceGrid = styled.div`
  display: grid;
  ${media.mq234`
    grid-template-columns: ${({ position }) =>
      position === 'left' ? '20fr 1fr 23fr' : '23fr 1fr 20fr'};
    grid-template-rows: minmax(auto, 300px) 1fr;
    align-items: start;
  `};
`

export default ServiceGrid
