const messages = {
  title1: 'Gruppentraining',
  description1:
    'Ob zwei Personen oder mehr: Für jede Konstellation stelle ich das passende Angebot, um gemeinsam sportliche Ziele jeder Art zu erreichen, zusammen. Die Gruppentrainings-Pakete sind selbstverständlich auf die Bedürfnisse der Gruppe zusammengestellt und entstehen im Rahmen eines ausführlichen Beratungsgesprächs.',
  imageAlt1: 'Tania mit zwei Kunden auf einer Wiese am Dehnen',
  title2: 'Firmen Fitness',
  description2:
    'Fitness- und Gesundheitsangebote für Ihre Firma sind eine lohnende Investition. Mit individuell angepassten Programmen und Angeboten, stärke ich Ihre Mitarbeiter. Das Gesundheitsbewusstsein und die Körperwahrnehmung werden dabei geschult. Durch die Bewegung bekommen Ihre Mitarbeiter mehr Kraft und Energie, um im beruflichen wie im privaten Alltag leistungsfähiger zu sein. Sport mindert nicht nur die Stressbelastung, sondern ist auch wichtig für die Mitarbeiterbindung indem es Teamgeist und das Betriebsklima fördert.',
  imageAlt2: 'vier Hände die sich in der Mitte die Hand geben',
  title3: 'Veranstaltungen und Seminare',
  description3Title1: 'Betriebliches Gesundheitsmanagement:',
  description3Text1:
    'Gerne unterstütze ich Sie bei der Einführung sowie Umsetzung von Massnahmen zur betrieblichen Gesundheitsförderung.',
  description3Title2: 'Firmenevents:',
  description3Text2:
    'Organisation von Gesundheitstag oder andere Aktionen in Ihrem Unternehmen vor Ort, nach Ihren Vorstellungen.',
  description3Title3: 'Vorträge / Workshops:',
  description3Text3: 'Rund um das Thema Fitness, Ernährung, Gesundheit.',
  imageAlt3:
    'Unterkörper einer Frau die einen Korb mit Gemüse und Früchten hält',
}

export default messages
