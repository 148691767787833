import React from 'react'

import messages from './messages'
import Section from './Section'
import ServiceGrid from './ServiceGrid'
import StyledTrapezoid from './StyledTrapezoid'
import Content from './Content'
import Title from './Title'
import Description from './Description'
import DescriptionTitle from './DescriptionTitle'
import ServiceImage from './ServiceImage'

const servicesData = [
  {
    description: (
      <Description position="left">{messages.description1}</Description>
    ),
    imageData: {
      alt: messages.imageAlt1,
      path: 'PageServiceGroupFitness',
      name: 'Streching_01',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MDE2IDQwMTYiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM2NTg3NjUiIGQ9Ik0wIDBoNDAxNnY0MDE2SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjggNy44KSBzY2FsZSgxNS42ODc1KSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTE5IDkuNSAxNi4yKSBzY2FsZSgyNS43NzI3OSAxMjcuMTYwMzEpIi8+PGVsbGlwc2UgY3g9IjEwNCIgY3k9IjYwIiByeD0iNDQiIHJ5PSI0NCIvPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTI3Ljg3NzM0IDkuODgyMjkgLTEzLjAzOTU1IC0zNi43ODM4IDE1My42IDkuMikiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC0xNTEuOSA1MS4yIDg5LjUpIHNjYWxlKDI3LjAyOTg0IDM5LjU4MzY4KSIvPjxlbGxpcHNlIGZpbGw9IiNlY2ZlYmEiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTIuMDc0NyAtMjQuNjIyNzYgMTE0LjYzNyAtOS42NTkyMiAxNjguMyAxMjIuMSkiLz48ZWxsaXBzZSBmaWxsPSIjMDA3M2ZhIiBjeD0iMzEiIGN5PSIxMjkiIHJ4PSIzMyIgcnk9IjQ0Ii8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTIyMiAxMWwzMS0yNyAxOCA4NXoiLz48ZWxsaXBzZSBmaWxsPSIjMDMwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDg3Ljc4NDA5IDIxLjg4NzAzIC00LjYyODkgMTguNTY1NTEgMjA0LjQgMjQwKSIvPjwvZz48L3N2Zz4=',
    },
    title: messages.title1,
    position: 'left',
  },
  {
    description: (
      <Description position="right">{messages.description2}</Description>
    ),
    imageData: {
      alt: messages.imageAlt2,
      path: 'PageServiceGroupFitness',
      name: 'FirmenFitness_01',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NTE1IDQ1MTUiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiNiOWFlYTciIGQ9Ik0wIDBoNDUxNXY0NTE1SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjggOC44KSBzY2FsZSgxNy42MzY3MikiIGZpbGwtb3BhY2l0eT0iLjUiPjxwYXRoIGZpbGw9IiMzMjAwMDAiIGQ9Ik04NC41IDEwOWwxMjUuOCA0My40LTIwLjggNjAuNS0xMjUuOC00My4zeiIvPjxlbGxpcHNlIGZpbGw9IiNmZmYiIGN4PSIyMDkiIGN5PSIzOSIgcng9IjUxIiByeT0iNTYiLz48cGF0aCBmaWxsPSIjMmMwMDAwIiBkPSJNMTE4LjYgNTYuOGwzMS4zLTYuNiA5LjUgNDUtMzEuMyA2LjZ6Ii8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxMS4yMzg0MSAtNDEuMzY0MjMgNjguOTU5NTkgMTguNzM1OSA1IDEyNy4zKSIvPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoNDIuODc5MSA0OS40ODY3NyAtMTguMTM5ODggMTUuNzE3NzcgMTcuNiAyNi42KSIvPjxlbGxpcHNlIGZpbGw9IiM2ZjQyMjEiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTcuNjA2OTMgLTE1LjUzNTQxIDEwOS4xNzA1IC01My40NTU0MiAxNDMuOSAxNDEpIi8+PHBhdGggZmlsbD0iIzI2MDAwMCIgZD0iTTIzMiAxNDdsLTEgMzMtOTUgOHoiLz48ZWxsaXBzZSBmaWxsPSJhenVyZSIgY3g9IjIyNiIgY3k9IjIyNSIgcng9IjQzIiByeT0iNDMiLz48L2c+PC9zdmc+',
    },
    title: messages.title2,
    position: 'right',
  },
  {
    description: (
      <>
        <DescriptionTitle as="h4" type="header4" position="left">
          {messages.description3Title1}
        </DescriptionTitle>
        <Description position="left">{messages.description3Text1}</Description>
        <DescriptionTitle as="h4" type="header4" position="left">
          {messages.description3Title2}
        </DescriptionTitle>
        <Description position="left">{messages.description3Text2}</Description>
        <DescriptionTitle as="h4" type="header4" position="left">
          {messages.description3Title3}
        </DescriptionTitle>
        <Description position="left">{messages.description3Text3}</Description>
      </>
    ),
    imageData: {
      alt: messages.imageAlt3,
      path: 'PageServiceGroupFitness',
      name: 'Seminare_01',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMzY3IDMzNjciPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiNiOGI1YTIiIGQ9Ik0wIDBoMzM2N3YzMzY3SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjYgNi42KSBzY2FsZSgxMy4xNTIzNCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiM5NTVmMDAiIGN4PSIxMzgiIGN5PSIxNDAiIHJ4PSI4NSIgcnk9Ijg1Ii8+PGVsbGlwc2UgZmlsbD0iI2Y5ZmZmZiIgY3g9IjI4IiBjeT0iMTE5IiByeD0iMzkiIHJ5PSIyNTUiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiBjeD0iMjM0IiBjeT0iMzciIHJ4PSI1NSIgcnk9IjU1Ii8+PHBhdGggZmlsbD0iIzMwMjMyYyIgZD0iTTE3OCA5NWw1LTk4TDgyLTE2eiIvPjxwYXRoIGZpbGw9IiNlYmZmZmYiIGQ9Ik0xNTAgMjcxTDI3MSA2MGwtMTYgMjA0eiIvPjxwYXRoIGZpbGw9IiNjNDcyMDAiIGQ9Ik0xOTMgMjE2bC0xMzggMkwyMzYgODV6Ii8+PGVsbGlwc2UgZmlsbD0iI2Y2ZmZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgtODUuMiAxMDguNiAtMTYpIHNjYWxlKDYyLjI5MDg1IDcuNTE2OTYpIi8+PGVsbGlwc2UgZmlsbD0iIzA0MjQ1MyIgY3g9Ijk0IiBjeT0iNzYiIHJ4PSIxNSIgcnk9IjQ5Ii8+PC9nPjwvc3ZnPg==',
    },
    title: messages.title3,
    position: 'left',
  },
]

const Services = () => (
  <Section>
    {servicesData.map(({ title, description, position, imageData }) => (
      <ServiceGrid key={title} position={position}>
        <ServiceImage imageData={imageData} position={position} />
        <StyledTrapezoid position={position}>
          <Content>
            <Title as="h3" type="header4" uppercase position={position}>
              {title}
            </Title>
            {description}
          </Content>
        </StyledTrapezoid>
      </ServiceGrid>
    ))}
  </Section>
)

export default Services
