import styled from 'styled-components'

import Typography from '~components/Typography'

const DescriptionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary.main};
  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing[16]};
  }
`

export default DescriptionTitle
